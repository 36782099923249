<template>
    <div class="protocol">
        <div class="protocol-wrap">
            <div class="protocol-image">
                <img :src="info.pdfPreview" alt="" />
                <div class="protocol-download">
                    <button @click="convertPdf">
                        <div class="protocol-download-wrap">
                            <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M21.2053 6.09072L15.6005 0.5315C15.5207 0.452422 15.4106 0.407715 15.2956 0.407715H6.94209C6.00722 0.407715 5.24663 1.13345 5.24663 2.02545V7.67696H1.86125C1.16181 7.67696 0.592773 8.21996 0.592773 8.88745V14.9473C0.592773 15.6147 1.16181 16.1577 1.86125 16.1577H5.24663V19.7928C5.24663 20.6833 6.00722 21.4077 6.94209 21.4077H19.6281C20.563 21.4077 21.3236 20.6837 21.3236 19.7937V6.37077C21.3236 6.26635 21.2812 6.16598 21.2053 6.09072ZM15.4005 1.49795L20.0015 6.06155H15.4005V1.49795ZM1.86125 15.35C1.62836 15.35 1.43896 15.1694 1.43896 14.9473V8.88745C1.43896 8.66535 1.62841 8.48464 1.86125 8.48464H13.2859C13.5188 8.48464 13.7082 8.66535 13.7082 8.88745V14.9473C13.7082 15.1694 13.5187 15.35 13.2859 15.35H1.86125ZM20.4774 19.7937C20.4774 20.2383 20.0964 20.6 19.6281 20.6H6.94209C6.47378 20.6 6.09277 20.2379 6.09277 19.7928V16.1577H13.2859C13.9853 16.1577 14.5543 15.6147 14.5543 14.9473V8.88745C14.5543 8.21996 13.9853 7.67696 13.2859 7.67696H6.09277V2.02545C6.09277 1.57875 6.47378 1.21539 6.94209 1.21539H14.5543V6.46539C14.5543 6.68844 14.7438 6.86923 14.9774 6.86923H20.4774V19.7937Z"
                                    fill="white"
                                />
                            </svg>
                            <span>{{ $t("download") }}</span>
                        </div>
                    </button>
                    <!--          <main-button :label="$t('download')" @change-event="convertPdf"/>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pdf from "vue-pdf";
import axios from "axios";

export default {
    name: "protocol",
    data() {
        return {};
    },
    props: {
        info: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        pdf,
    },
    methods: {
        async convertPdf() {
            const response = await axios.get(this.info.file, { responseType: "blob" });
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = this.info.fileName;
            link.click();
            URL.revokeObjectURL(link.href);
        },
    },
};
</script>

<style scoped lang="scss">
.protocol {
    &-image {
        height: 320px;
        width: 100%;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-download {
        button {
            height: 43px;
            max-width: 247px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            background-color: #4f4f4f;
            bottom: 25px;
            right: -40px;

            .protocol-download-wrap {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 9px;
                }

                span {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #fcfcfc;
                }
            }

            &:hover {
                background-color: #fcfcfc;
                border: 1px solid #4f4f4f;
                transition: ease-in-out 0.3s;

                .protocol-download-wrap {
                    span {
                        color: #4f4f4f;
                    }

                    svg {
                        path {
                            fill: #4f4f4f;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1140px) {
    .protocol-image {
        height: 600px;

        img {
            object-fit: cover;
        }
    }
    .protocol-download {
        right: 0;
        bottom: 0;
    }
}

@media screen and (max-width: 660px) {
    .protocol-image {
        height: 400px;

        img {
            //object-fit: contain;
        }
    }
}
</style>
