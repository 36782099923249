
import _ from "lodash";

import blockWithImage from "../components/block-with-image/index.vue";
import titleWithDescription from "../components/title-with-description/index.vue";
import phrase from "../components/phrase/index.vue";
import blockWithVideo from "../components/block-with-video/index.vue";
import mapGeolocation from "../components/map/index.vue";
import certificate from "../components/certificate/index.vue";
import patent from "../components/patent/index.vue";
import ingredients from "../components/ingredient/index.vue";
import oneNew from "../components/new/index.vue";
import beforeAfter from "../components/before-after/index.vue";
import protocol from "../components/protocol/index.vue";
import terms from "../components/terms/index.vue";
import description from "../components/description/index.vue";
import partner from "../components/partner/index.vue";
import blockWithImageWithButton from "../components/block-with-image-with-button/index.vue";
import banner from "../components/banner/index.vue";
import staticContent from "@/components/static-content/index.vue";
import imageLeft from "@/modules/pages/components/image-left";
import columnBlock from "@/modules/pages/components/column-block/index.vue";
import metodistBlock from "./component/index.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";
import { downloadFileFrom } from "../../../helpers";

export default {
    name: "page-wrap",
    components: {
        banner,
        blockWithImage,
        titleWithDescription,
        phrase,
        blockWithVideo,
        mapGeolocation,
        certificate,
        patent,
        ingredients,
        oneNew,
        beforeAfter,
        protocol,
        terms,
        description,
        partner,
        blockWithImageWithButton,
        staticContent,
        imageLeft,
        columnBlock,
        metodistBlock,
    },
    data() {
        return {
            loadingMoreContent: false,
            currentlyPlaying: null,

            ingredientSkip: 4,
            certificatesSkip: 4,
            patentsSkip: 4,

            cooperationTerms: [],
            webinarCourseThemes: [],

            newsList: [],
            newsPaginator: {
                hasNextPage: false,
                page: 1,
                perPage: 3,
            },
            downloadFileLoading: false,
            activeBrand: 'renew'
        };
    },
    computed: {
        ...mapGetters({
            page: "pages/page",
            pageSectionBlocks: "pages/pageSectionBlocks",

            headerMenu: "setting/headerMenu",
        }),
        ingredientsList() {
            return _.take(this.page.innovativeIngredients?.data || [], this.ingredientSkip);
        },
        certificatesList() {
            return _.take(this.page.qualityCertificates?.data || [], this.certificatesSkip);
        },
        patentsList() {
            return _.take(this.page.patents?.data || [], this.patentsSkip);
        },
    },
    watch: {
        "newsPaginator.page"() {
            this.fetchNewsForPage();
        },
    },
    async created() {
        await this.fetchContentForPage();
        await this.fetchContent();
    },
    methods: {
        ...mapActions({
            fetchPage: "pages/GET_PAGE",
            fetchNewsPage: "pages/GET_NEWS_PAGE",
            fetchTerm: "pages/GET_ONE_TERM",

            fetchNews: "pages/GET_NEWS",
            fetchCooperationTerms: "pages/COOPERATION_TERMS",
            fetchCourseThemes: "pages/WEBINAR_COURSE_THEMES",
        }),
        ...mapMutations({
            changeApplicationFormPopup: `popups/CHANGE_APPLICATION_FORM_POPUP`,
        }),
        handleVideoPlay(metodistBlock) {
            if (this.currentlyPlaying && this.currentlyPlaying !== metodistBlock) {
                this.currentlyPlaying.$refs.videoRef.pause();
                this.currentlyPlaying.playVideo = false;
            }

            this.currentlyPlaying = metodistBlock;
        },
        async fetchContentForPage() {
            if (!this.$route.query.type) {
                await this.fetchPage(this.$route.params.slug);
            } else if ("terms" === this.$route.query.type) {
                await this.fetchTerm(this.$route.params.slug);
            } else if ("new" === this.$route.query.type) {
                await this.fetchNewsPage(this.$route.params.slug);
            }
        },
        async fetchContent() {
            if ("scientific-news" === this.page.configType) {
                this.newsList = [];
                await this.fetchNewsForPage();
            } else if ("terms-of-cooperation" === this.page.configType) {
                this.cooperationTerms = await this.fetchCooperationTerms();
            } else if ("online-education" === this.page.configType) {
                this.webinarCourseThemes = await this.fetchCourseThemes();
            }
        },
        async fetchNewsForPage() {
            this.loadingMoreContent = true;

            const newsData = await this.fetchNews({
                page: this.newsPaginator.page,
                perPage: this.newsPaginator.perPage,
            });

            this.newsList.push(...newsData.data);

            this.newsPaginator.hasNextPage = !!newsData.links.next;

            this.loadingMoreContent = false;
        },
        isBigSecondTitle() {
            const pageConfigTypesForTitle = [
                "catalog-and-protocols",
                "application-for-presentation",
                "before-after",
                "terms-of-cooperation",
                "scientific-news",
                "patents",
                "innovative-ingredients",
                "partners",
            ];

            return this.$route.query.smallTitle || pageConfigTypesForTitle.includes(this.page.configType);
        },
        showPageTitle() {
            const ignoreList = [
                'catalog-and-protocols'
            ]
            return !ignoreList.includes(this.page.configType);
        },
        loadMoreIngredients(skip) {
            this.ingredientSkip += skip;
        },
        loadMoreCertificates(skip) {
            this.certificatesSkip += skip;
        },
        loadMorePatents(skip) {
            this.patentsSkip += skip;
        },
        loadMoreNews() {
            this.newsPaginator.page += 1;
        },
        async downloadAllFiles(files) {
            try {
                this.downloadFileLoading = true;
                await Promise.all(files.map(item => downloadFileFrom(item.file, item.fileName)));
            } catch (error) {
                console.error("Error downloading files:", error);
                // Handle the error as needed
            } finally {
                this.downloadFileLoading = false;
            }
        },
        getOnlyFiles(array){
            return array.map(item => {
                return {
                    file: item.file,
                    fileName: item.fileName
                }
            })
        },
    },
};
