<template>
    <div class="block-with__video">
        <div class="block-with__video-title">{{ info.title }}</div>
        <div class="block-with__video-description" v-html="info.description"></div>

        <div class="block-with__video-video">
            <button @click="playVideo"></button>
                  <video width="100%" height="600" controls="controls" playsinline ref="video">
                    <source :src="info.video" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
                  </video>
<!--            <iframe-->
<!--                :src="`https://www.youtube.com/embed/ISmvHpvWm3g`"-->
<!--                width="100%"-->
<!--                height="600"-->
<!--                allowfullscreen-->
<!--                frameborder="0"-->
<!--                allow="autoplay; encrypted-media"-->
<!--                type="text/html"-->
<!--            ></iframe>-->
        </div>
    </div>
</template>

<script>
export default {
    name: "block-with-video",
    data() {
        return {
            isPlaying: false,
        };
    },
    props: {
        info: {
            type: Object,
            default: () => {},
        },
    },

    methods: {
        playVideo() {
            let video = this.$refs.video;
            video.load();
            if (!this.isPlaying) {
                this.isPlaying = true;
                video.play();
            } else {
                this.isPlaying = false;
                video.pause();
            }
        },
    },
};
</script>

<style lang="scss">
.block-with__video {
    margin-top: 55px;

    &-title {
        font-weight: normal;
        font-size: 24px;
        line-height: 29px;
        color: #333333;
        margin-bottom: 20px;
    }

    &-description {
        font-weight: normal;
        font-size: 16px;
        line-height: 1.3;
        color: #4f4f4f;
        margin-bottom: 55px;

        ul {
            li {
            }
        }

        p {
            line-height: 1.3;
        }
    }

    &-video {
        position: relative;
        z-index: 1;

        button {
            //position: absolute;
            //z-index: 999;
            //top: 0;
            //height: 90%;
            //width: 100%;
        }
    }
}

@media screen and (max-width: 500px) {
    .block-with__video-video {
        video {
            height: 220px;
        }
    }
}
</style>
